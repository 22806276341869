class Translation {
	constructor() {
		this.changeLanguage('en')
	}

	changeLanguage = lang => {
		this.lang = lang || 'en'
		const keys = Object.keys(this.strings[this.lang])
		const config = this.strings[this.lang]
		keys.forEach(key => (this[key] = config[key]))
	}

	strings = {
		en: {
			inProgress: 'Email Verification in progress',
			success: 'Email verification Successful',
			invalidLink: 'Link is invalid',
			contactSupport: 'Please contact support',
			linkExpired: 'Link has Expired',
			linkExpiredText: 'Please resend the verification email from App',
			genericErrorMessage: 'Something went wrong',
			invalidFieldValues: 'Invalid field values',
			confirmPasswordFailed: 'Two passwords that you enter is inconsistent!',
			resetPasswordTitle: 'Create New Password',
			passwordHelp: 'The password must be 6-20 in length.',
			newPasswordPlaceholder: 'New Password',
			confirmPasswordPlaceholder: 'Confirm Password',
			changePasswordButtonText: 'Change Password',
			passwordChangeSuccess: 'Password changed successfully',
			loginWithNewPassword: 'Please login the App with new password',
			inputPasswordMessage: 'Please input your Password!',
			passwordHelpMin: 'Password must be 6 or more characters',
			passwordHelpMax: 'Password should be 20 or less characters',
			TOKEN_EXPIRED : 'Token Expired',
			USER_NOT_FOUND : 'User Not Found'
		},
		de: {
			inProgress: 'E-Mail-Bestätigung läuft',
			success: 'E-Mail-Bestätigung erfolgreich',
			invalidLink: 'Der Link ist ungültig',
			contactSupport: 'Bitte wenden Sie sich an den Support',
			linkExpired: 'Link abgelaufen',
			linkExpiredText: 'Dieser Link ist abgelaufen. Fordern Sie zur Bestätigung eine neue E-Mail an',
			genericErrorMessage: 'Etwas ist schief gelaufen',
			invalidFieldValues: 'Ungültige Feldwerte',
			confirmPasswordFailed: 'Zwei von Ihnen eingegebene Passwörter sind inkonsistent!',
			resetPasswordTitle: 'Neues Passwort erstellen',
			passwordHelp: 'Das Passwort muss 6-20 lang sein.',
			newPasswordPlaceholder: 'Neues Kennwort',
			confirmPasswordPlaceholder: 'Kennwort bestätigen',
			changePasswordButtonText: 'Passwort ändern',
			passwordChangeSuccess: 'das Passwort wurde erfolgreich geändert',
			loginWithNewPassword: 'Bitte loggen Sie sich mit neuem Passwort in die App ein',
			inputPasswordMessage: 'Bitte geben Sie Ihr Passwort ein!',
			passwordHelpMin: 'Das Passwort muss aus 6 oder mehr Zeichen bestehen',
			passwordHelpMax: 'Das Passwort darf maximal 20 Zeichen lang sein',
			TOKEN_EXPIRED : 'Token abgelaufen',
			USER_NOT_FOUND : 'Benutzer wurde nicht gefunden'
		},
		it: {
			inProgress: 'Verifica email in corso',
			success: 'Verifica email riuscita',
			invalidLink: 'Il link non è valido',
			contactSupport: 'si prega di contattare il servizio di assistenza',
			linkExpired: 'Link scaduto',
			linkExpiredText: 'Questo link è scaduto. Richiedi una nuova e-mail per la conferma',
			genericErrorMessage: 'Qualcosa è andato storto',
			invalidFieldValues: 'Valori di campo non validi',
			confirmPasswordFailed: 'Due password inserite non sono coerenti!',
			resetPasswordTitle: 'Crea nuova password',
			passwordHelp: 'La password deve essere lunga 6-20.',
			newPasswordPlaceholder: 'Nuova Password',
			confirmPasswordPlaceholder: 'Conferma Password',
			changePasswordButtonText: 'Cambia la password',
			passwordChangeSuccess: 'Password cambiata con successo',
			loginWithNewPassword: `Accedi all'app con una nuova password`,
			inputPasswordMessage: 'Per favore inserisci la tua password!',
			passwordHelpMin: 'La password deve contenere 6 o più caratteri',
			passwordHelpMax: 'La password deve contenere 20 o meno caratteri',
			TOKEN_EXPIRED : 'Token scaduto',
			USER_NOT_FOUND : 'Utente non trovato'
		},
		sv: {
			inProgress: 'E-postverifiering pågår',
			success: 'E-postverifiering Framgångsrik',
			invalidLink: 'Länken är ogiltig',
			contactSupport: 'Kontakta supporten',
			linkExpired: 'Länken har utgått',
			linkExpiredText: 'Skicka igen verifieringsmeddelandet från appen',
			genericErrorMessage: 'Något gick fel',
			invalidFieldValues: 'Ogiltiga fältvärden',
			confirmPasswordFailed: 'Två lösenord som du anger är inkonsekvent!',
			resetPasswordTitle: 'Skapa nytt lösenord',
			passwordHelp: 'Lösenordet måste vara 6-20 långt.',
			newPasswordPlaceholder: 'Nytt lösenord',
			confirmPasswordPlaceholder: 'Bekräfta lösenord',
			changePasswordButtonText: 'Ändra lösenord',
			passwordChangeSuccess: 'lösenordet har ändrats',
			loginWithNewPassword: 'Logga in appen med nytt lösenord',
			inputPasswordMessage: 'Ange ditt lösenord!',
			passwordHelpMin: 'Lösenordet måste vara 6 eller fler tecken',
			passwordHelpMax: 'Lösenordet bör vara minst 20 tecken',
			TOKEN_EXPIRED : 'Token har upphört',
			USER_NOT_FOUND : 'Användaren hittades inte'
		},
		pl: {
			inProgress: 'Trwa weryfikacja adresu e-mail',
			success: 'Weryfikacja adresu e-mail powiodła się',
			invalidLink: 'Link jest nieprawidłowy',
			contactSupport: 'Skontaktuj się z pomocą techniczną',
			linkExpired: 'Link wygasł',
			linkExpiredText: 'Wyślij ponownie weryfikacyjny e-mail z aplikacji',
			genericErrorMessage: 'Coś poszło nie tak',
			invalidFieldValues: 'Nieprawidłowe wartości pól',
			confirmPasswordFailed: 'Dwa wprowadzone hasła są niespójne!',
			resetPasswordTitle: 'Utwórz nowe hasło',
			passwordHelp: 'Hasło musi mieć długość 6-20.',
			newPasswordPlaceholder: 'Nowe hasło',
			confirmPasswordPlaceholder: 'Potwierdź hasło',
			changePasswordButtonText: 'Zmień hasło',
			passwordChangeSuccess: 'Hasło zostało zmienione',
			loginWithNewPassword: 'Zaloguj się do aplikacji przy użyciu nowego hasła',
			inputPasswordMessage: 'Wpisz swoje hasło!',
			passwordHelpMin: 'Hasło musi składać się z co najmniej 6 znaków',
			passwordHelpMax: 'Hasło powinno mieć maksymalnie 20 znaków',
			TOKEN_EXPIRED : 'Token stracił ważność',
			USER_NOT_FOUND : 'Użytkownik nie znaleziony'
		},
	}
}

export default new Translation()
