import React, { Component } from 'react'
import { Page, Wrapper, Logo } from '../sharedComponents/styles'
import { Typography } from 'antd'
import styled from 'styled-components'
import Translation from './Translation'

export const PasswordSuccessImage = styled.img`
	max-width: 50vw;
	height: ${(props) => props.height};
	width: ${(props) => props.height};
	content: url('/images/password-success.svg');
	margin: 2rem 0;
`

const { Title, Text } = Typography

export default class PasswordSuccess extends Component {
	render() {
		return (
			<Page>
				<Wrapper className='card'>
					<Logo />
					<PasswordSuccessImage />
					<Title level={4} style={{ color: '#262626' }}>
						{Translation.passwordChangeSuccess}
					</Title>
					<Text>{(!!this.props.message && this.props.message) || Translation.loginWithNewPassword}</Text>
				</Wrapper>
			</Page>
		)
	}
}
