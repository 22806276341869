import React, { Component } from 'react'
import { Page } from '../sharedComponents/styles'
import { Typography } from 'antd'
import styled from 'styled-components'

const { Title } = Typography
const Container = styled.div`
	text-align: left;
	padding: 4rem;
	width: 60vw;

	@media only screen and (max-device-width: 425px) {
		width: 95vw;
		padding: 2rem;
	}
`

export default class PrivacyPolicy extends Component {
	render() {
		return (
			<Page>
				<Container>
					<Title style={{ color: '#262626' }}>Privacy Policy</Title>
					{/* <embed style={{ width: '100%', height: '85vh' }} src={`/tnc_docs/tnc_${process.env.REACT_APP_TENANT}.pdf#toolbar=0`} /> */}
					<div dangerouslySetInnerHTML={{ __html: privacyPolicyText[process.env.REACT_APP_TENANT] }}></div>
				</Container>
			</Page>
		)
	}
}

const privacyPolicyText = {
	ELITEPARKING: `
Elite Parking is committed to protecting your privacy. This Privacy Policy (“Policy”) is applicable to everyone who uses “the Service”, including the application, website and services performed as a part of the application or website. This Privacy Policy deals with the method and purpose of collection of information by Elite Parking before, during and after your use of the Service. This Privacy Policy also applies if someone else uses the Service on your behalf. If any word or phrase has been defined within the Terms and Conditions, but has not been defined within this Privacy Policy, that word / phrase will have the meaning that has been assigned to it in the Terms and Conditions.<br/><br/>
What type of personal information do we collect?<br/><br/>
We may collect the following information from you:<br/><br/>
1. Elite Parking does not collect any information from your device without your explicit consent.<br/><br/>
3. Information that you choose to provide to us on our ‘Contact Us’ webpage or in the course of communication with our staff via email, phone calls or otherwise, shall be collected by us. This may include your name, email address, country and any other information that you provide to us, including any other personally identifiable information provided by you.<br/><br/>
How is personal information used?<br/><br/>
The personal information collected from you shall be used for specific purposes only and shall only pertain to any contracts entered into between us. The Personal Information collected from you shall be used to improve customer service and support services, to personalize users experience, to improve the Service, to process payments or refunds, to send regular e-mails, to conduct contests, surveys, promotions, or for any other purpose specified at the time of collection of the information. The information collected may be used for any purpose necessary for a contract. The information can also be used for sending any updates, or any special offers from third parties.<br/><br/>
Your Personal Information shall be kept confidential and shall not be used for any other reason except for reasons clearly specified in this Privacy Policy. We may require the disclosure of your Personal Information to third parties in case of a corporate divestiture or dissolution, or if required by law.<br/><br/>
Sensitive Personal Information<br/><br/>
Unless specifically requested, we ask that you not send us and you shall not disclose, on or through the Service or otherwise to us, any sensitive personal information (e.g., information related to racial or ethnic origin, political opinions, religion, ideological or other beliefs, health, biometrics or genetic characteristics, national identification numbers, social security numbers, criminal background, trade union membership, or administrative or criminal proceedings and sanctions).<br/><br/>
Who do we share the information with?<br/><br/>
Except as provided in this Policy, your information will never be sold, traded, rented or shared with any other legal entity for any purpose except:<br/><br/>
(a) transfer to third party service providers who help us facilitate our business activities;<br/><br/>
(b) the information may be shared in case of a merger or acquisition;<br/><br/>
(c) service providers that facilitate contacting you;<br/><br/>
(d) service providers that facilitate storing data; and<br/><br/>
(e) legal disclosures.<br/><br/>
Data Storage and Retention<br/><br/>
Elite Parking is a company registered in Hawaii. We store data on AWS servers located in California. We will retain Information for the period necessary to fulfil the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.<br/><br/>
The criteria used to determine our retention periods include:<br/><br/>
(a) The length of time we have an ongoing relationship with you and provide the Service to you (for example, for as long as you keep using our Service);<br/><br/>
(b) Whether you would reasonably expect us to retain your data until you ask us to delete it;<br/><br/>
(c) Whether there is a legal obligation to which we are subject to (for example, certain laws may require us to keep records of your transactions for a certain period of time before we can delete them);<br/><br/>
(d) Whether retention is advisable considering our legal position (for example, for statutes of limitations, legal disputes or regulatory investigations).<br/><br/>
Security<br/><br/>
As the first line of defense against unwanted intrusions against your personal information, we avoid collecting your personal information wherever possible. The information that is collected by us is protected with strict access controls.<br/><br/>
User Rights<br/><br/>
You have a number of important rights with respect your Personal Information. In summary, those include rights to:<br/><br/>
(a) Access and update your Information - When you use the Service or the Website, we make good faith efforts to provide you, as and when requested by you, with access to your Information. Further, if there is any change in your Information, you may update it by accessing the relevant page of the Website, or by contacting us. If you have any questions about how we use the Information collected which relates to you, please contact us. We will endeavor to update your Information within 14 working days of any new or updated Information being provided to us, in order to ensure that the Information we hold about you is as accurate and up to date as possible;<br/><br/>
(b) Require us to correct any mistakes in your Information - In such case we shall ensure that any Information or data found to be inaccurate or deficient shall be corrected or amended as feasible, subject to any requirement for such Information to be retained by law or for legitimate business purposes;<br/><br/>
(c) Require the erasure of your Information in certain situations;<br/><br/>
(d) Require us to restrict processing or object at any time to processing of personal Information in certain situations;<br/><br/>
(e) Receive the Personal Information concerning you which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit that data to a third party in certain situations;<br/><br/>
(f) Object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you;<br/><br/>
(g) Object in certain other situations to our continued processing of your personal Information;<br/><br/>
(h) Otherwise restrict our processing of your personal Information in certain circumstances.<br/><br/>
We ask individual users to identify themselves and the Information requested to be accessed, corrected or removed before processing such requests or inform other limitations you would like to put on our use of your personal Information. If you would like to exercise any of those rights, please let us know the Information to which your request relates by emailing, calling or writing to us and providing us enough information to identify you [(e.g. account number, user name, registration details)]. Our Contact Details are available at the end of this document.<br/><br/>
If you are accessing the Service from the European Union, Iceland, Lichtenstein or Norway, you have the right to complain to your local data protection authority.<br/><br/>
Cookie Notice<br/><br/>
When you visit our website, Elite Parking places certain cookies on your device that are necessary for the provision of the service.<br/><br/>
We may collect certain information about your online activity including among other things, number of guest visits, number of user visits, frequency of visits and total number of visitors to the website and specific pages. The information collected is only for internal use and is collected to understand your interests and preferences, improve your overall experience and for marketing.<br/><br/>
The parties with whom we work may place, view, and/or use “cookies”, web server logs, web beacons, or mapping pixel/ pixel tag or other electronic tools to collect statistical and other information about your use of the Service. This information may include, among other things, information about the IP address of your computer, browser type, language, operating system, your mobile device, geo-location data, the state or country from which you accessed the Service, the web pages visited, the date and the time of a visit, the websites you visited immediately before and after visiting the Service, the number of links you click within the Service, the functions you use on the Service, the searches you request on the Service, the data you save on or download from the Service.<br/><br/>
Links to Third Party Websites or Services<br/><br/>
The Service may contain links to third party websites or services. Please be cautious while sharing or viewing information using such links. We are not responsible for the privacy practices of third-party websites or services. We advise you to carefully read and understand the privacy policy and terms of service of any such third-party website or service.<br/><br/>
Communications from the Service<br/><br/>
We may use your email address to send updates and alerts with respect to the Service. If you do not wish to receive such communication, you can opt out by clicking ‘unsubscribe’ at the end of such an email or by sending an email to eliteappsupport@eliteparking.net. <br/><br/>
Testimonials<br/><br/>
We may publish your comments / reviews / testimonials on our Service. Such comments / reviews / testimonials may contain certain personal information. Please contact us if you feel that a comment / review / testimonial has been published with your personal information without your consent.<br/><br/>
Legal Disclosures<br/><br/>
We withhold the right to impart with your personal data as required by applicable law, in reply to legal requests by public authorities, including meeting national security or law enforcement requirements and when we believe that disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or other legal process served on us.<br/><br/>
Updates and Changes to the Privacy Policy<br/><br/>
We may add, change, update or modify this Privacy Policy at any time if we change our practices or procedures to help us serve and protect you better. We encourage you to review this Privacy Policy from time to time to ensure that you are aware of any changes to this Privacy Policy and will endeavor to notify you of any changes or updates. By continuing to use the Service, you provide consent to any modified terms. If do not consent to any terms contained in this Privacy Policy, please discontinue the use of the Service.<br/><br/>
Contact Us<br/><br/>
If you have any queries about this privacy policy or your dealings with us, you may contact us at:<br/><br/>
eliteappsupport@eliteparking.net<br/><br/>
Elite Parking Services<br/><br/>
900 Fort Street Mall, Suite 1240<br/><br/>
Honolulu, HI 96813<br/><br/>




`,
}
