import styled from 'styled-components'
import { Form } from 'antd'

export const Page = styled.div`
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`

export const Wrapper = styled.div`
	display: flex;
	max-width: 90vw;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 2rem 4rem;
	margin: 1rem;
	&.card {
		color: #262626;
		background: white;
		box-shadow: 0px 0px 25px rgba(69, 107, 132, 0.15);
		border-radius: 4px;
		min-width: 300px;
	}
`

export const Logo = styled.img`
	max-width: 60vw;
	height: ${props => props.height};
	width: ${props => props.height};
	content: url(${props => props.theme.logoUrl});
`

export const FormItem = styled(Form.Item)`
	&& {
		display: ${props => (props.inputType === 'inline' ? 'flex' : 'block')};
		margin: 0;
		height: 80px;
		align-items: center;
	}

	.ant-form-item-label {
		text-align: left;
	}

	.ant-form-item-children > * {
		width: ${props => (props.inputType !== 'inline' ? '100%' : '')};
	}
`
