import React, { Component } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Route, Redirect } from 'react-router'
import VerifyEmail from './VerifyEmail'
import VerifyFieldUpdate from './VerifyFieldUpdate'
import ResetPassword from './ResetPassword'
import NotFoundPage from './NotFoundPage'
import qs from 'qs'
import TermsAndConditions from './TermsAndConditions'
import PrivacyPolicy from './PrivacyPolicy'
import ViewEmail from './ViewEmail'
import CreatePassword from './CreatePassword'

const pages = {
	resetPassword: 'resetPassword',
	verifyEmail: 'verifyEmail',
	viewInBrowser: 'viewInBrowser',
	createPassword: 'createPassword',
}

export default class Routers extends Component {
	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route exact path='/verifyEmail' component={VerifyEmail} />
					<Route exact path='/resetPassword' component={ResetPassword} />
					<Route exact path='/createPassword' component={CreatePassword} />
					<Route exact path='/tnc' component={TermsAndConditions} />
					<Route exact path='/privacypolicy' component={PrivacyPolicy} />
					<Route exact path='/viewInBrowser' component={ViewEmail} />
					<Route path='/' component={PathResolver} />
				</Switch>
			</BrowserRouter>
		)
	}
}

class PathResolver extends Component {
	state = {}

	componentDidMount() {
		const [, searchString] = this.props.location.search.split('?')
		const queryParams = qs.parse(searchString)
		const { pageType } = queryParams
		if (!!pageType) {
			delete queryParams.pageType
			this.setState({ pageType, queryParams })
		}
	}


	render() {
		const { pageType, queryParams } = this.state
		const params = qs.stringify(queryParams)
		if (pageType === 'tnc') return <Redirect to='/tnc' />
		if (pageType === 'privacypolicy') return <Redirect to='/privacypolicy' />
		if (pageType === 'verifyFieldUpdate') return <VerifyFieldUpdate />
		return (!!pageType && !!pages[pageType] && !!params && <Redirect to={`/${pages[pageType]}?${params}`} />) || <NotFoundPage />
	}
}
