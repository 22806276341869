import React, { Component } from 'react'
import { getRequestTo } from '../utils/requestComponents'
import styles from './style.css'

class ViewEmail extends Component {
	state = {
		htmlString: '',
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search)
		const uuid = params.get('uuid')
		const htmlString = await getRequestTo(`/authentication/v1/consumer/v2/viewInBrowser/${uuid}`, null, true, true).then((res) => res.text())
		this.setState({ htmlString })
	}

	render() {
		return (
			<div style={{ height: '100vh', width: '100%', textAlign: 'left' }} className={styles}>
				<div dangerouslySetInnerHTML={{ __html: this.state.htmlString }} />
			</div>
		)
	}
}

export default ViewEmail
