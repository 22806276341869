import React, { Component } from 'react'
import { Page } from '../sharedComponents/styles'
import { Typography } from 'antd'
import styled from 'styled-components'

const { Title } = Typography
const Container = styled.div`
	text-align: left;
	padding: 4rem;
	width: 60vw;

	@media only screen and (max-device-width: 425px) {
		width: 95vw;
		padding: 2rem;
	}
`

export default class TermsAndConditions extends Component {
	render() {
		return (
			<Page>
				<Container>
					<Title style={{ color: '#262626' }}>Terms and conditions</Title>
					{/* <embed style={{ width: '100%', height: '85vh' }} src={`/tnc_docs/tnc_${process.env.REACT_APP_TENANT}.pdf#toolbar=0`} /> */}
					<div dangerouslySetInnerHTML={{ __html: tncs[process.env.REACT_APP_TENANT] }}></div>
				</Container>
			</Page>
		)
	}
}

const tncs = {
	ELITEPARKING: `This website/app is provided by Elite Parking and its affiliates (collectively, “Elite Parking”). The terms "we", "us", "our" and "Elite Parking" refer to Elite Parking, and our corporate affiliates and websites (collectively, "Elite Parking"). The terms "You" and "User" refer to visitors and users of the website or app (collectively, “the Service”). <br/><br/>
The Service is offered to you conditioned upon your acceptance without modification of any/all the terms, conditions, and notices set forth below (collectively, the "Agreement"). By accessing or using this Service in any manner, whether through a computer, a mobile phone or any other device, you agree to be bound by the Agreement and represent that you have read and understood its terms. Please read the Agreement carefully, as it contains information concerning your legal rights and limitations on these rights, as well as a section regarding applicable law and jurisdiction of disputes. If you do not accept all of these terms and conditions you are not authorized to use this Service.
We may change or otherwise modify the Agreement in the future in accordance with the Terms and Conditions herein, and you understand and agree that your continued access or use of this Website after such change signifies your acceptance of the updated or modified Agreement. Be sure to return to this page periodically to review the most current version of the Agreement. <br/><br/>
You warrant that you possess the legal authority to enter into this Agreement under the applicable laws and to use this Website in accordance with all terms and conditions herein. <br/><br/>
Privacy Policy <br/><br/>
Elite Parking believes in protecting your privacy. Any personal information you provided to us during your use of the Service will be used in accordance with our Privacy Policy. Our Privacy Policy forms a part of these Terms. <br/><br/>
Third Party Content <br/><br/>
The Service may contain links to external third-party websites, apps or services. Elite Parking does not guarantee, approve or endorse the information or products available on these third-party websites, nor does a link indicate any association with or endorsement of the linked website. <br/><br/>
We do not operate or control and have no responsibility for the information, products and/or services found on any external sites. Nor does it represent or endorse the accuracy or reliability of any information, products and/or services provided on or through any external sites, including, without limitation, warranties of any kind, either express or implied, warranties of title or non-infringement or implied warranties of merchant-ability or fitness for a particular purpose. <br/><br/>
Users of the Service assume complete responsibility and risk in their use of any external sites. Users should direct any concerns regarding any external link to the relevant website’s administrator or webmaster. Users are hereby informed to exercise utmost caution when visiting any third party website. <br/><br/>
Intellectual Property <br/><br/>
All material, data and information included on the Service (the “Content”) is the property of Elite Parking, its subsidiaries, affiliated companies, suppliers and/or customers and is protected by international copyright laws. Any unauthorized modification or use of the Content violates Elite Parking’s intellectual property rights and is prohibited. Copying, transmission, reproduction, replication, posting or redistribution of the Content or any portion thereof is strictly prohibited without the prior written permission of Elite Parking. To request permission, you may contact Elite Parking at eliteappsupport@eliteparking.net. <br/><br/>
You should assume that everything you see or read on the Service is proprietary information unless otherwise noted and may not be used except as provided in these Terms of Use or with Elite Parking’s prior written consent in each case. Nothing posted on the Service grants a license to any trademarks, copyrights or other intellectual property rights, whether by implication, estoppel or otherwise. Trademarks displayed on the Service not owned by Elite Parking are the property of their respective owners. <br/><br/>
Limitation on Use <br/><br/>
You agree not to: <br/><br/>
(a) access, monitor or copy any content or information from this Service using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission; <br/><br/> 
(b) take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure; <br/><br/>
(c) "frame", "mirror" or otherwise incorporate any part of this Service into any other website without our prior written authorization; or <br/><br/>
(d) attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Elite Parking in connection with the Service or the services. <br/><br/>
Indemnity <br/><br/>
You agree to defend and indemnify Elite Parking and its affiliates and any of their officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees brought by third parties as a result of: <br/><br/>
(a) your breach of this Agreement or the documents referenced herein; <br/><br/>
(b) (b)your violation of any law or the rights of a third party; or <br/><br/>
(c) (c)your use of this Service. <br/><br/>
Notice and Take-Down <br/><br/>
If you have any complaints or objections to material or content posted on the Service, or if you believe that material or content posted on the Service infringes a copyright or trademark or any other Intellectual Property Rights that you hold, please contact us immediately at eliteappsupport@eliteparking.net to initiate the takedown process. Once the takedown process has been initiated, Elite Parking will make all reasonable efforts to remove illegal content within a reasonable time. <br/><br/>
Modifications to the Service <br/><br/>
The Content and the functionality of the Service may be updated or changed at any time without prior notice. <br/><br/>
Modifications to these Terms <br/><br/>
Elite Parking may change, add or delete these Terms of Use or any portion thereof from time to time in its sole discretion where it deems it necessary for legal, general regulatory and technical purposes, or due to changes in the services provided or nature or layout of the Service. By continuing to use this website after such a modification, you agree to be bound by any such amended Terms of Use. <br/><br/>
Elite Parking may change, suspend or discontinue any aspect of the Service at any time, including availability of any feature, database or content. Elite Parking may also impose limits on certain features and services or restrict your access to all or parts of the Service without notice or liability for technical or security reasons, to prevent against unauthorized access, loss of, or destruction of data or where we consider in our sole discretion that you are in breach of any provision of these Terms of Use or of any law or regulation in force in a jurisdiction in which Elite Parking operates. <br/><br/>
Warranty Disclaimer <br/><br/>
Though all efforts have been made to ensure the accuracy of the Content, Elite Parking does not guarantee that the Website or the Content is error free. Elite Parking makes no representations about the accuracy or functionality of the Service or that the Content is accurate, error free or up to date. <br/><br/>
Neither Elite Parking, any of its affiliates, directors, officers and employees, nor any other party involved in creating, producing or delivering the Service or the Content is liable for any direct, incidental, consequential, indirect or punitive damages arising out of your access to, or use of, the Service or the operation of the Service or failure of the Service to function in any way. In no event is Elite Parking liable for any direct, indirect, incidental, special, punitive, or consequential, damages or any damages whatsoever, even if Elite Parking has been previously advised of the possibility of such damages. These limitations shall apply notwithstanding any failure of essential purpose of any limited remedy. Your acceptance of this limitation of liability is an essential term of this agreement and You acknowledge that Elite Parking would not grant access to the Website without your agreement to this term. <br/><br/>
Remedies for Violations <br/><br/>
Elite Parking reserves the right to seek all remedies available at law and in equity for violations of these Terms. <br/><br/>
Governing Law and Jurisdiction <br/><br/>
This Agreement shall be governed by and construed in accordance with the laws of the State of Hawaii. The parties expressly agree that all legal actions arising out of this agreement shall be filed in Honolulu, Hawaii. <br/><br/>
Severability <br/><br/>
If any term of this Agreement is found to be unlawful, void, illegal or unenforceable in any respect under any law, then that clause shall be deemed to be severable from the remaining clauses and the validity, legality and enforceability of other clauses shall not be affected in any way. <br/><br/>
Entire Agreement <br/><br/>
This Agreement constitutes the entire and whole agreement between the User and Elite Parking, this Agreement shall supersede all other communications between Elite Parking and its Users with respect to the subject matter hereof. If at any time, the User finds that the Terms under this Agreement are unacceptable or if the User does not agree to these Terms, then the user may not use this Service. Continued use by any user shall be sufficient to indicate acceptance of all terms under this Agreement. <br/><br/>
Contact Information <br/><br/>
For answers to your questions or any other help required, you may contact us at eliteappsupport@eliteparking.net <br/><br/>
Thank you for visiting our website. <br/><br/>

`,
}
