import { getRequestTo } from '../utils/requestComponents'

export const sidebarHidingBreakpoint = 450
export const checkForMobileDevices = () => window.innerWidth <= sidebarHidingBreakpoint

export const queryString = params =>
	Object.keys(params)
		.map(key => {
			const value = Array.isArray(params[key]) ? params[key].join(',') : params[key]
			return key + '=' + value
			// return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
		})
		.join('&')

export function camelize(str) {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
			return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
		})
		.replace(/\s+/g, '')
}

export function slugify(text) {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w-]+/g, '') // Remove all non-word chars
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, '') // Trim - from end of text
}

export const capitalize = string => {
	if (!string) {
		return ''
	}

	const hasSpaces = string.includes(' ')
	string = string.toLowerCase()

	const capitalizeWord = word => word.charAt(0).toUpperCase() + word.slice(1)

	return hasSpaces
		? string
				.split(' ')
				.map(capitalizeWord)
				.join(' ')
		: capitalizeWord(string)
}

export const validateTIN = number => {
	const TinRegex = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/
	return TinRegex.test(number)
}

export const fetchList = async (entity, query = {}, avoidErrorNotification) => {
	const data = await getRequestTo(`/authentication/v1/${entity}`, query, { avoidErrorNotification })
	return data
}

export const getDefault = arr => (arr && !!arr.length && (arr.find(({ isDefault }) => !!isDefault) || arr[0])) || {}

export const delayedPromise = async (promise, delay = 1000) => {
	const timerPromise = new Promise(resolve => setTimeout(resolve, delay))
	const response = await Promise.all([timerPromise, promise]).catch(err => {
		throw new Error(err)
	})
	return response[1]
}

export const intersection = (arr1, arr2) => arr1.filter(value => -1 !== arr2.indexOf(value))

export const convertBooleanToBinaryInteger = object =>
	Object.keys(object).reduce((total, key) => {
		total[key] = typeof object[key] === 'boolean' ? (object[key] ? 1 : 0) : object[key]
		return total
	}, {})

// To chunk array into arrays of given size;
export const chunk = (array, size) => {
	const chunked_arr = []
	for (let i = 0; i < array.length; i++) {
		const last = chunked_arr[chunked_arr.length - 1]
		if (!last || last.length === size) {
			chunked_arr.push([array[i]])
		} else {
			last.push(array[i])
		}
	}
	return chunked_arr
}
