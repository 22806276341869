import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/less/main.less'
import registerServiceWorker from './registerServiceWorker'

window.latestBuild = process.env.REACT_APP_LATEST_BUILD ? new Date(process.env.REACT_APP_LATEST_BUILD).toISOString() : null

ReactDOM.render(<App />, document.getElementById('root'))
registerServiceWorker()
