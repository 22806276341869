import React, { Component } from 'react'
import { Result, Typography } from 'antd'
import { Page, Wrapper, Logo } from '../sharedComponents/styles'
const { Title, Text } = Typography

export default class NotFoundPage extends Component {
	render() {
		return (
			<Page>
				<Wrapper className='card'>
					<Logo />
					<Result status='404' />
					<Title level={3} style={{ color: 'black' }}>
						404
					</Title>
					<Text>The page you are looking for does not exists.</Text>
				</Wrapper>
			</Page>
		)
	}
}
