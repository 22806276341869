import React, { Component } from 'react'
import { Page, Wrapper, Logo } from '../sharedComponents/styles'
import { Typography, Spin } from 'antd'
import { postRequestTo } from '../utils/requestComponents'
import styled from 'styled-components'
import Translation from './Translation'

const { Title, Text } = Typography
const status = {
	SUCCESS: 'success',
	ERROR: 'error',
	LOADING: 'loading',
}

export const EmailClipart = styled.img`
	max-width: 50vw;
	height: ${(props) => props.height};
	width: ${(props) => props.height};
	content: url('/images/email-${({ status }) => status}.svg');
	margin: 2rem 0;
`

export default class VerifyEmail extends Component {
	state = {
		verified: status.LOADING,
		message: Translation.inProgress,
	}

	componentDidMount() {
		const params = new URLSearchParams(this.props.history.location.search)
		const { token, expiresOn, language } = {
			token: params.get('token'),
			expiresOn: params.get('expiresOn'),
			language: params.get('language'),
		}

		try {
			Translation.changeLanguage(language)
			this.setState({ language })
		} catch (e) {
			Translation.changeLanguage('en')
			this.setState({ language: 'en' })
		} finally {
			this.verifyEmail(token)
		}

		if (!token || !expiresOn) return this.setState({ verified: status.ERROR, message: Translation.invalidLink, errorMessage: Translation.contactSupport })
		if (Date.now() > expiresOn) return this.setState({ verified: status.ERROR, message: Translation.linkExpired, errorMessage: Translation.linkExpiredText })
	}

	verifyEmail = async (token) => {
		try {
			const response = await postRequestTo(`/authentication/v1/consumer/v2/setEmailVerified`, { token }, 'POST', true)
			if (!!response) return this.setState({ verified: status.SUCCESS, message: Translation.success, errorMessage: null })
			else throw new Error({ message: Translation.genericErrorMessage })
		} catch (e) {
			this.setState({ verified: status.ERROR, message: Translation.genericErrorMessage, errorMessage: e.message })
		}
	}

	render() {
		const { verified, message, errorMessage } = this.state
		return (
			<Page>
				<Spin spinning={verified === status.LOADING}>
					<Wrapper className='card'>
						<Logo />
						<EmailClipart status={verified} />
						<Title level={4} style={{ color: '#262626' }}>
							{message}
						</Title>
						{!!errorMessage && <Text>{errorMessage}</Text>}
					</Wrapper>
				</Spin>
			</Page>
		)
	}
}
